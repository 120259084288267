import React from 'react';
import PhotoSlider from './photoslider';
import MediaPlayer from './mediaplayer';
import NewsSlider from './newsslider';
import GroupTitle from './grouptitle';
import HeaderMenu from './headermenu';
import ItemsMenu from './itemsmenu';
import './menu1366x768.css';

function Menu() {
    return (
        <div className="container">
            <PhotoSlider />
            <MediaPlayer />
            <NewsSlider />
            <GroupTitle />
            <HeaderMenu />
            <ItemsMenu />
        </div>
    );
}

export default Menu;