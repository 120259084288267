import React, { useState, useEffect } from 'react';
import { parseString } from 'xml2js';
import axios from 'axios';

function NewsSlider() {
    const [datasrc, setDatasrc] = useState();
    const api = axios.create({
        baseURL: 'https://www.bbc.com',
        withCredentials: true,
        headers: {
            'Access-Control-Allow-Origin' : '*',
            'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            'Accept' : '*/*',
            'Content-Type' : 'application/xml',
        }
    });    

    useEffect(() => {
        api
            .get('/portuguese/topicos/brasil/index.xml')
            .then((response) => response.text())
            .then((responseText) => {
                parseString(responseText, function (err, result) {
                    console.log(result);
                    setDatasrc(result);
                })
            })
            .catch((error) => {
                console.log('Error fetching then feed: ', error);
            });
    }, []);            

    return (
        <div className="newsSlider">{ datasrc }</div>
    );
}

export default NewsSlider;