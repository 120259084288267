import React, { Component } from 'react';

class headerMenu extends Component {
    constructor() {
        super();
        this.state = { imgurl : 'https://www.tempo.com/wimages/fotoad91186ec887429322ad72eb6b6e2869.png?' + Math.floor(Math.random() * 999999)}
    }

    componentDidMount() {
        setInterval(() => {
            this.setState({
                imgurl : 'https://www.tempo.com/wimages/fotoad91186ec887429322ad72eb6b6e2869.png?' + Math.floor(Math.random() * 999999)
            })
        }, 10*60*1000);
    }

    render() {
        return (
            <div className="headerMenu">
                <div className="headerMenu banner"></div>
                <div className="headerMenu logo"></div>
                <div className="headerMenu prevtempo">
                    <div className="headerMenu imgcontainer"><img className="headerMenu img" alt="Previsão do Tempo" prop="Previsão do Tempo" src={this.state.imgurl} /></div>
                </div>
            </div>
        );
    }
}

export default headerMenu;