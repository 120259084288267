import Menu1366x768 from './pages/menu1366x768';

function App() {
  return (
    <div className="App">
      <Menu1366x768 />
    </div>
  );
}

export default App;
